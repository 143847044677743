export const config = {
    RPC_URL: 'https://rpc-sentinel-ia.cosmosia.notional.ventures',
    REST_URL: 'https://api-sentinel-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/sentinel',
    STAKING_URL: 'https://sentinel.omniflix.co/stake',
    NETWORK_NAME: 'Sentinel',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'sentinelhub-2',
    CHAIN_NAME: 'Sentinel',
    COIN_DENOM: 'DVPN',
    COIN_MINIMAL_DENOM: 'udvpn',
    COIN_DECIMALS: 6,
    PREFIX: 'sent',
    COIN_TYPE: 118,
    COSMOSTAION: 'sentinel',
    COINGECKO_ID: 'sentinel',
    GAS_PRICE_STEP_LOW: 0.1,
    GAS_PRICE_STEP_AVERAGE: 0.25,
    GAS_PRICE_STEP_HIGH: 0.4,
    FEATURES: ['ibc-transfer'],
};
